import React, {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
// import Select, {SelectChangeEvent} from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import {
  getUid,
  getNowDate,
  createQuestion,
  // getCategories,
} from "../utils/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  border: "2px solid #CDCDCD",
  boxShadow: 24,
  p: 4,
};

function WriteQuestionModal(props) {
  const {open, handleClose} = props;
  // const [categoryList, setCategoryList] = useState([]);
  const [message, setMessage] = useState("");
  // const [category, setCategory] = useState("");
  const [checked, setChecked] = useState(false);
  const [statusSent, setStatusSent] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!open) {
      setMessage("");
      // setCategory("");
      setChecked(false);
      setStatusSent(false);
      setUploading(false);
    }
  }, [open]);

  // useEffect(() => {
  //   (async () => {
  //     const categories = await getCategories();
  //     setCategoryList(categories);
  //   })();
  // }, []);

  // const handleChangeCategory = (event) => {
  //   setCategory(event.target.value);
  // };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  async function sendQuestion(question) {
    //user check
    if (question.fromUid === null) {
      alert(
        "エラーが発生しました。ブラウザを一度閉じてから、このページを開いてください",
      );
      return null;
    }
    //validation
    if (question.message === "") {
      alert("本文を入力してください");
      return null;
    }
    if (question.message.length < 20) {
      alert("本文が短すぎます");
      return null;
    }
    // if (question.category === "") {
    //   alert("カテゴリを選択してください");
    //   return null;
    // }
    if (question.checked === false) {
      alert("同意にチェックしてください");
      return null;
    }
    question.answersNum = 0;
    question.status = 0;
    delete question.checked;
    //create
    await createQuestion(question).then(() => setStatusSent(true));
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        {statusSent ? (
          <Stack>
            <Typography>送信しました。</Typography>
            <Typography>
              審査が完了するまで少々お待ち下さい。通過しなかった場合は通知されません。
            </Typography>
            <Button onClick={() => handleClose()} color="primary">
              閉じる
            </Button>
          </Stack>
        ) : (
          <Stack>
            <Typography component="h5" variant="h5">
              AIに相談してみましょう（登録不要）
            </Typography>

            {/* <Typography id="modal-title" variant="h5" component="h2">
            タイトル
          </Typography> */}
            {/* <TextField
            id="outlined-multiline-static-title"
            placeholder="入力してください"
          /> */}

            <Stack>
              <TextField
                id="outlined-multiline-static-message"
                multiline
                rows={8}
                placeholder="本文を入力してください。詳細に入力すると、より正確な回答が得られます"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Stack>

            <Stack sx={{pt: 4}} direction="row" alignItems="center" spacing={2}>
              <Checkbox
                checked={checked}
                onChange={handleChangeChecked}
                inputProps={{"aria-label": "controlled"}}
              />
              <Stack>
                <Typography>投稿内容の掲載について同意する</Typography>
                <Typography>
                  ※内容の審査が行われ、完了し次第、掲載されます
                </Typography>
              </Stack>
            </Stack>

            <Button
              onClick={async () => {
                setUploading(true);
                await sendQuestion({
                  message: message,
                  // category: category,
                  checked: checked,
                  fromUid: getUid(),
                  createdAt: getNowDate(),
                });
                setUploading(false);
              }}
              color="primary"
              disabled={uploading}
            >
              送信する
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
}

export default WriteQuestionModal;
