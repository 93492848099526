import React, {useState, useEffect} from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import {getUid, createReplyToAiMessage} from "../utils/index";

const CustomButton = styled(Button)({
  backgroundColor: "#1DA1F2",
  width: "80%",
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  border: "2px solid #CDCDCD",
  boxShadow: 24,
  p: 4,
};

function WriteReplyMessageModal(props) {
  const {
    open,
    replyTo,
    handleClose,
    setRepliedFlagToAiAnswer,
    setRepliedFlagToAiAnswerReplyMessage,
    twitterLink,
  } = props;
  const [message, setMessage] = useState("");
  const [type, setType] = useState(0);
  const [checked, setChecked] = useState(false);
  const [statusSent, setStatusSent] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!open) {
      setMessage("");
      setType(0);
      setChecked(false);
      setStatusSent(false);
      setUploading(false);
    }
  }, [open]);

  const handleChangeType = (event) => {
    setType(parseInt(event.target.value));
  };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  async function sendReplyMessage(replyMessage) {
    //user check
    if (replyMessage.fromUid === null) {
      alert(
        "エラーが発生しました。ブラウザを一度閉じてから、このページを開いてください",
      );
      return null;
    }
    //validation
    if (replyMessage.message === "") {
      alert("本文を入力してください");
      return null;
    }
    if (replyMessage.message.length < 10) {
      if (replyMessage.type === 1) {
        alert("本文が短すぎます");
        return null;
      }
    }
    if (replyMessage.type !== 0 && replyMessage.type !== 1) {
      alert("種別を選択してください");
      return null;
    }
    if (replyMessage.checked === false) {
      alert("同意にチェックしてください");
      return null;
    }
    if (!replyMessage.toMessageId) {
      alert("エラーが発生しました");
      return null;
    }
    if (!replyTo.qid) {
      alert("エラーが発生しました");
      return null;
    }
    if (!replyTo.originMessageId) {
      alert("エラーが発生しました");
      return null;
    }

    delete replyMessage.checked;
    //create
    await createReplyToAiMessage(
      replyTo.qid,
      replyTo.originMessageId,
      replyMessage,
    ).then(() => {
      if (replyMessage.toMessageId === replyTo.originMessageId) {
        setRepliedFlagToAiAnswer();
      } else {
        setRepliedFlagToAiAnswerReplyMessage();
      }
      setStatusSent(true);
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        {statusSent ? (
          type === 0 ? (
            <Stack alignItems="center">
              <Box>
                <Typography>ご利用ありがとうございました。</Typography>
              </Box>
              <Box sx={{marginY: 2}}>
                <Typography>AIの回答結果をSNSで共有しましょう!!</Typography>
              </Box>
              <CustomButton variant="contained" href={twitterLink}>
                Twitterで共有する
              </CustomButton>
              <Button onClick={() => handleClose()} color="primary">
                閉じる
              </Button>
            </Stack>
          ) : type === 1 ? (
            <Stack>
              <Typography>送信しました。</Typography>
              <Typography>
                審査が完了するまで少々お待ち下さい。通過しなかった場合は通知されません。
              </Typography>
              <Button onClick={() => handleClose()} color="primary">
                閉じる
              </Button>
            </Stack>
          ) : null
        ) : (
          <Stack>
            <Typography component="h5" variant="h5">
              AIに返信する
            </Typography>

            {/* <Typography id="modal-title" variant="h5" component="h2">
            タイトル
          </Typography> */}
            {/* <TextField
            id="outlined-multiline-static-title"
            placeholder="入力してください"
          /> */}

            <Stack>
              <TextField
                id="outlined-multiline-static-message"
                multiline
                rows={4}
                placeholder="本文を入力してください。詳細に入力すると、より正確な回答が得られます"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Stack>

            <Stack style={{marginTop: 10}}>
              <Typography id="modal-type" variant="h5">
                返信の種別
              </Typography>

              <FormControl>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender
                </FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={type}
                  onChange={handleChangeType}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="感謝・お礼（AIへの相談を終了）"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="回答に対してさらに質問する"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>

            <Stack sx={{pt: 4}} direction="row" alignItems="center" spacing={2}>
              <Checkbox
                checked={checked}
                onChange={handleChangeChecked}
                inputProps={{"aria-label": "controlled"}}
              />
              <Stack>
                <Typography>投稿内容の掲載について同意する</Typography>
                <Typography>
                  ※内容の審査が行われ、完了し次第、掲載されます
                </Typography>
              </Stack>
            </Stack>

            <Button
              onClick={async () => {
                setUploading(true);
                await sendReplyMessage({
                  aiFlag: false,
                  fromUid: getUid(),
                  toMessageId: replyTo.toMessageId,
                  message: message,
                  type: type,
                  checked: checked,
                });
                setUploading(false);
              }}
              color="primary"
              disabled={uploading}
            >
              送信する
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
}

export default WriteReplyMessageModal;
