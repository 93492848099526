import {initializeApp} from "firebase/app";
// 3. Authを使うので、以下をインポートする
import {getAuth, GoogleAuthProvider} from "firebase/auth";
// 4. firestoreを使うので、以下をインポートする
import {getFirestore} from "firebase/firestore";
import {getFunctions} from "firebase/functions";
// import {connectFunctionsEmulator} from "firebase/functions";

const REGION_TOKYO = "asia-northeast1";

const firebaseConfig = {
  apiKey: "AIzaSyCJRZLH0BVl_jVCvKLaG3MNne0pSEiZER8",
  authDomain: "ai-chiehiroba.firebaseapp.com",
  projectId: "ai-chiehiroba",
  storageBucket: "ai-chiehiroba.appspot.com",
  messagingSenderId: "167418426631",
  appId: "1:167418426631:web:5af88fc7e591eaabb941a2",
  measurementId: "G-6V6RX5M9R9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// 4. authとfirebaseそれぞれ実行、インスタンス化
const auth = getAuth(app);
const functions = getFunctions();
functions.region = REGION_TOKYO;
// connectFunctionsEmulator(functions, "localhost", 5001);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// exportしてどこからでも使えるようにする
export {auth, functions, provider, db};
