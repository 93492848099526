import React, {useState, useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";

import {styled} from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import WriteReplyMessageModal from "../component/WriteReplyMessageModal";

import {Helmet} from "react-helmet-async";

import {
  getQuestionByQuestionId,
  getQuestionAiAnswer,
  getQuestionAiAnswerReplyMessages,
  getUid,
} from "../utils/index";

const CustomButton = styled(Button)({
  backgroundColor: "#EE9898",
  width: "80%",
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
});

const domain = "https://chiehiroba.ai";

function Post() {
  const {questionPath} = useParams();
  const [question, setQuestion] = useState(null);
  const [aiAnswer, setAiAnswer] = useState(null);
  const [aiAnswerReplyMessages, setAiAnswerReplyMessages] = useState([]);
  const [yourQuestionFlag, setYourQuestionFlag] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [open, setOpen] = useState(false);
  // const [pageUrl, setPageUrl] = useState("");
  const [twitterLink, setTwitterLink] = useState("");

  const handleOpen = (replyTo) => {
    setReplyTo(replyTo);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setReplyTo(null);
  };
  const setRepliedFlagToAiAnswer = () => {
    const new_aiAnswer = aiAnswer;
    new_aiAnswer.repliedFlag = true;
    setAiAnswer(new_aiAnswer);
  };
  const setRepliedFlagToAiAnswerReplyMessage = () => {
    const newArray = aiAnswerReplyMessages;
    newArray[newArray.length - 1].repliedFlag = true;
    setAiAnswerReplyMessages(newArray);
  };
  const handleOpenAnswer = () => {
    alert("ただいま準備中です...");
  };

  // const location = useLocation();
  // const state = location.state;

  useEffect(() => {
    (async () => {
      const questionId = parseInt(questionPath.slice(1));
      console.log("questionId: " + questionId);
      // setPageUrl(domain + "/qa/q" + questionId);

      const question = await getQuestionByQuestionId(questionId);
      setQuestion(question);

      const uid = getUid();
      if (uid === question.fromUid) {
        setYourQuestionFlag(true);
      }

      const aiAnswer = await getQuestionAiAnswer(question.id);
      setAiAnswer(aiAnswer);

      const aiAnswerReplyMessages = await getQuestionAiAnswerReplyMessages(
        question.id,
        aiAnswer.id,
      );
      setAiAnswerReplyMessages(aiAnswerReplyMessages);
      console.log(aiAnswerReplyMessages);

      const substring = aiAnswer.message.substring(0, 40);
      setTwitterLink(
        "https://twitter.com/intent/tweet?text=参考になりました！%0a%0aAI：「" +
          substring +
          "...」気になるAIの回答の続きはこちら↓%0a" +
          "&url=" +
          domain +
          "/qa/q" +
          questionId +
          "%0a%0a&hashtags=AI恋愛相談ひろば",
      );
    })();
  }, [questionPath]);

  return (
    <>
      <Helmet title={question?.title} />
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="md">
          <Card
            sx={{
              padding: 4,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                component="h1"
                variant="h2"
                // align="center"
                color="text.primary"
                gutterBottom
              >
                {question?.title}
              </Typography>
              <Typography
                component="h2"
                variant="p"
                // align="center"
                color="text.secondary"
                paragraph
              >
                {question?.message}
              </Typography>
            </Box>
          </Card>

          <Stack
            sx={{marginTop: 4, height: 60}}
            direction="row"
            alignItems="center"
          >
            <Avatar
              alt="ai-avatar"
              src="https://firebasestorage.googleapis.com/v0/b/ai-chiehiroba.appspot.com/o/Image%2Fai-avatar.png?alt=media&token=64dd111b-42ab-4235-aa50-5ee4d802fb3d"
              sx={{width: 52, height: 52}}
            />
            <Box
              sx={{marginLeft: 2}}
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                component="h2"
                variant="h3"
                color="text.primary"
                gutterBottom
              >
                AIの回答
              </Typography>
            </Box>
          </Stack>

          <Stack>
            <Card
              sx={{
                marginTop: 2,
                padding: 4,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                component="p"
                variant="h6"
                color="text.secondary"
                paragraph
                fontWeight="bold"
              >
                {aiAnswer?.message}
              </Typography>
              {/* <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                paragraph
              >
                {aiAnswer?.createdAt}
              </Typography> */}

              {yourQuestionFlag && aiAnswerReplyMessages.length === 0 ? (
                aiAnswer?.repliedFlag ? (
                  <CardActions>
                    <Button
                      size="medium"
                      onClick={() =>
                        handleOpen({
                          qid: question.id,
                          originMessageId: aiAnswer.id,
                          toMessageId: aiAnswer.id,
                        })
                      }
                      disabled={true}
                    >
                      返信済み
                    </Button>
                  </CardActions>
                ) : (
                  <CardActions>
                    <Button
                      size="medium"
                      onClick={() =>
                        handleOpen({
                          qid: question.id,
                          originMessageId: aiAnswer.id,
                          toMessageId: aiAnswer.id,
                        })
                      }
                    >
                      返信する
                    </Button>
                  </CardActions>
                )
              ) : null}

              {twitterLink !== "" && (
                <Stack alignItems="flex-end">
                  <Button
                    sx={{
                      width: 200,
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    href={twitterLink}
                  >
                    <Box>
                      <Typography color="text.secondary">
                        役に立った！
                      </Typography>
                    </Box>
                    <Box
                      component="img"
                      sx={{width: 36, height: 30}}
                      alt="twitter-logo"
                      src="https://firebasestorage.googleapis.com/v0/b/ai-chiehiroba.appspot.com/o/Image%2Ftwitter-log.png?alt=media&token=fa623830-88a8-4ea2-8155-c47ea06ba75f"
                    />
                  </Button>
                </Stack>
              )}
            </Card>
          </Stack>

          {aiAnswerReplyMessages?.map((replyMessage, index) => {
            if (replyMessage.aiFlag) {
              return (
                <Box
                  sx={{padding: 4, borderLeft: 5, borderColor: "#898989"}}
                  key={replyMessage.id}
                >
                  <Stack sx={{height: 50}} direction="row" alignItems="center">
                    <Avatar
                      alt="ai-avatar"
                      src="https://firebasestorage.googleapis.com/v0/b/ai-chiehiroba.appspot.com/o/Image%2Fai-avatar.png?alt=media&token=64dd111b-42ab-4235-aa50-5ee4d802fb3d"
                      sx={{width: 44, height: 44}}
                    />
                    <Box
                      sx={{marginLeft: 1}}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        component="h4"
                        variant="h5"
                        color="text.secondary"
                        paragraph
                      >
                        AIからの返信
                      </Typography>
                    </Box>
                  </Stack>
                  <Box sx={{marginTop: 1}}>
                    <Typography
                      component="p"
                      variant="p"
                      color="text.secondary"
                      paragraph
                    >
                      {replyMessage.message}
                    </Typography>
                  </Box>
                  {yourQuestionFlag &&
                  index === aiAnswerReplyMessages.length - 1 ? (
                    replyMessage.repliedFlag ? (
                      <Button
                        size="medium"
                        onClick={() =>
                          handleOpen({
                            qid: question.id,
                            originMessageId: aiAnswer.id,
                            toMessageId: replyMessage.id,
                          })
                        }
                        disabled={true}
                      >
                        返信済み
                      </Button>
                    ) : (
                      <Button
                        size="medium"
                        onClick={() =>
                          handleOpen({
                            qid: question.id,
                            originMessageId: aiAnswer.id,
                            toMessageId: replyMessage.id,
                          })
                        }
                      >
                        返信する
                      </Button>
                    )
                  ) : null}
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{padding: 4, borderLeft: 5, borderColor: "#CDCDCD"}}
                  key={replyMessage.id}
                >
                  <Typography
                    component="h4"
                    variant="h5"
                    color="text.secondary"
                    paragraph
                  >
                    相談者さんからの返信
                  </Typography>
                  <Typography
                    component="p"
                    variant="p"
                    color="text.secondary"
                    paragraph
                  >
                    {replyMessage.message}
                  </Typography>
                </Box>
              );
            }
          })}

          <Box sx={{marginTop: 4, marginLeft: 2}}>
            <Typography
              component="h2"
              variant="h3"
              color="text.primary"
              gutterBottom
            >
              みんなの回答
            </Typography>
          </Box>

          <Stack sx={{marginTop: 4}} direction="column" alignItems="center">
            {yourQuestionFlag ? (
              <Typography variant="h5" color="text.secondary" paragraph>
                準備中です...
              </Typography>
            ) : (
              <CustomButton variant="contained" onClick={handleOpenAnswer}>
                登録して回答する
              </CustomButton>
            )}
          </Stack>

          <WriteReplyMessageModal
            open={open}
            replyTo={replyTo}
            handleClose={handleClose}
            setRepliedFlagToAiAnswer={setRepliedFlagToAiAnswer}
            setRepliedFlagToAiAnswerReplyMessage={
              setRepliedFlagToAiAnswerReplyMessage
            }
            twitterLink={twitterLink}
          />
        </Container>
      </Box>
    </>
  );
}

export default Post;
