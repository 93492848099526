import React, {useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import NewQuestion from "../component/NewQuestion";
import {Helmet} from "react-helmet-async";

function QuestionList() {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Helmet
        title="新着相談 - AI恋愛相談ひろば"
        meta={[
          {
            name: "description",
            content:
              "「AI恋愛相談ひろば」は、AI技術を活用した恋愛相談コミュニティで、恋愛に関する悩みや疑問を解決します。AIの迅速な回答やみんなのコメントをもとに、恋愛に悩んでいる男女をサポートします。",
          },
        ]}
      />
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="md">
          <NewQuestion page={page} />

          <Stack spacing={2} alignItems="center">
            <Pagination count={10} page={page} onChange={handleChange} />
          </Stack>
          {/* <h1>新規登録ページ</h1>
          <div>
            ログインは<Link to={`/login/`}>こちら</Link>
          </div>
          <div>
            <Link to={`/`}>ホームに戻る</Link>
          </div> */}
        </Container>
      </Box>
    </>
  );
}

export default QuestionList;
