import React, {useState, useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Toppage from "./container/Toppage";
import QuestionList from "./container/QuestionList";
import Posts from "./container/Posts";
import Post from "./container/Post";
import Copyright from "./component/Copyright";
import "./App.css";

import {auth} from "./firebase";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#d87274",
        // light: "#ffa2a3",
        // dark: "#a34449",
      },
      neutral: {
        main: "#d79b4a",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1025,
        xl: 1536,
      },
    },
  });
  theme.typography.h2 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem",
    },
  };
  theme.typography.h3 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.7rem",
    },
  };
  theme.typography.h5 = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
  };
  theme.typography.h6 = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  theme.typography.p = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    fontWeight: 100,
  };

  //auth
  useEffect(() => {
    console.log("testtesttest");

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        signInAnonymously(auth);
      } else {
        console.log(user);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="relative"
        sx={{
          background:
            "linear-gradient(106.73deg, #FECACA 25%, #A78BFA 130.87%)",
        }}
      >
        <Toolbar>
          <Button variant="contained" color="primary" href="/">
            <Typography
              color="inherit"
              noWrap
              sx={{fontSize: 18, fontWeight: "bold"}}
            >
              AI恋愛相談ひろば
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>

      <main>
        {/* <div className="App"> */}
        <BrowserRouter>
          <Routes>
            <Route path={`/`} element={<Toppage />} />
            <Route path={`/questions/`} element={<QuestionList />} />
            <Route path="/qa/" element={<Posts />}>
              <Route path=":questionPath" element={<Post />} />
            </Route>
          </Routes>
        </BrowserRouter>
        {/* </div> */}
      </main>

      {/* Footer */}
      <Box sx={{bgcolor: "background.paper", p: 6}} component="footer">
        <Typography component="h4" variant="h6" align="center" gutterBottom>
          現在β版のサービスであり、今後は検索やコミュニティ、専門家登録などの機能追加を予定しています。
        </Typography>
        {/* <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
        サブタイトル
        </Typography> */}
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}

export default App;
