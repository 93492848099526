import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Promotion() {
  return (
    <Stack sx={{marginTop: 4}} alignItems="center">
      <Typography
        component="h2"
        variant="h2"
        align="center"
        color="text.primary"
        paragraph
      >
        【宣伝】サークリー | 声で繋がるSNSアプリ
      </Typography>
      <Typography
        component="h4"
        variant="h2"
        align="center"
        color="text.secondary"
        paragraph
      >
        声だけのライブ配信やコミュニティでゆるく雑談、みんなでワイワイ話そう！
      </Typography>

      <Link sx={{maxWidth: "90%", height: "auto"}} href="https://circley.jp/">
        <Box
          component="img"
          sx={{
            border: 1,
            borderColor: "#ABABAB",
            maxWidth: "100%",
            height: "auto",
          }}
          alt="toppage-banner"
          src="https://firebasestorage.googleapis.com/v0/b/ai-chiehiroba.appspot.com/o/Image%2Fcircley-promotion-02.png?alt=media&token=a15db3ca-83a5-416a-93d5-156aa1eff02f"
        />
      </Link>
    </Stack>
  );
}

export default Promotion;
