import {db, auth, functions} from "../firebase";
import {httpsCallable} from "firebase/functions";
import {
  // doc,
  collection,
  addDoc,
  // getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";

function getUid() {
  const user = auth.currentUser;
  if (user) {
    return user.uid;
  } else {
    return null;
  }
}

function getNowDate() {
  return serverTimestamp();
}

async function getNewQuestions() {
  const PAGE_SIZE = 6;

  const collRef = collection(db, "questions");
  const snapshot = await getDocs(
    query(collRef, orderBy("createdAt", "desc"), limit(PAGE_SIZE)),
  );
  const questions = snapshot.docs.map((doc) => {
    if (doc.exists) {
      const data = doc.data();
      return data;
    } else {
      return {};
    }
  });
  return questions;
}

async function searchNewQuestions(page) {
  const PAGE_SIZE = 9;

  const uid = getUid();
  const from = (page - 1) * PAGE_SIZE;
  const size = PAGE_SIZE;

  const func = httpsCallable(functions, "onCallSearchNewQuestions");
  const returnVal = await func({uid: uid, from: from, size: size}).then(
    (res) => {
      console.log(res);
      const response = res.data;

      if (!response.error) {
        console.log(response.hits);
        const result = response.hits;
        const promises = result.map(async (hit) => {
          const question = hit._source;

          //add from_user data to feed
          // const qid = hit._id;
          question.createdAt = Timestamp.fromDate(
            new Date(question["createdAt"]),
          );
          question["@timestamp"] = Timestamp.fromDate(
            new Date(question["@timestamp"]),
          );
          return question;
        });

        const data = Promise.all(promises);
        return data;
      } else {
        console.log(response.error);
        return {error: true};
      }
    },
  );

  return returnVal;
}

async function getQuestionByQuestionId(questionId) {
  const collRef = collection(db, "questions");
  const snapshot = await getDocs(
    query(collRef, where("questionId", "==", questionId), limit(1)),
  );
  let question = null;
  snapshot.docs.map((doc) => {
    if (doc.exists) {
      question = doc.data();
      question.id = doc.id;
    }
    return null;
  });
  return question;
}

async function getQuestionAiAnswer(qid) {
  const questionAiAnswerRef = collection(db, "questions", qid, "aiAnswer");
  const snapshot = await getDocs(questionAiAnswerRef);
  let answer = null;
  snapshot.docs.map((doc) => {
    if (doc.exists) {
      answer = doc.data();
      answer.id = doc.id;
      console.log(answer);
    }
    return null;
  });
  return answer;
}

async function getQuestionAiAnswerReplyMessages(qid, messageId) {
  const collRef = collection(
    db,
    "questions",
    qid,
    "aiAnswer",
    messageId,
    "replyMessages",
  );
  const snapshot = await getDocs(query(collRef, orderBy("createdAt", "asc")));
  const replyMessages = snapshot.docs.map((doc) => {
    if (doc.exists) {
      const data = doc.data();
      data.id = doc.id;
      return data;
    } else {
      return {};
    }
  });
  return replyMessages;
}

async function createQuestion(question) {
  const uid = getUid();
  const userQuestionRef = collection(db, "users", uid, "questions");
  await addDoc(userQuestionRef, question);
}

async function createReplyToAiMessage(qid, originMessageId, replyMessage) {
  const uid = getUid();
  const func = httpsCallable(functions, "onCallCreateReplyToAiMessage");
  const returnVal = await func({
    uid: uid,
    qid: qid,
    originMessageId: originMessageId,
    replyMessage: replyMessage,
  });
  return returnVal;
}

// async function getCategories() {
//   const settingDocRef = doc(db, "setting", "v1");
//   const document = await getDoc(settingDocRef);
//   if (document.exists) {
//     const setting = document.data();
//     const categories = setting.categories;
//     return categories;
//   } else {
//     return [];
//   }
// }

export {
  getUid,
  getNowDate,
  getNewQuestions,
  searchNewQuestions,
  getQuestionByQuestionId,
  getQuestionAiAnswer,
  getQuestionAiAnswerReplyMessages,
  createQuestion,
  createReplyToAiMessage,
  // getCategories,
};
