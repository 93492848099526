import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Description() {
  return (
    <>
      <Box>
        <Typography
          component="h2"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          「AI恋愛相談ひろば」は、みんなでAIを活用し、恋愛の悩みを解決する完全匿名コミュニティです。
        </Typography>
        <Typography
          component="h3"
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
        >
          AIが最短10秒以内に回答してくれます。AIの回答に対してあなたが返信し、会話を続けることもできます。
        </Typography>
      </Box>
    </>
  );
}

export default Description;
