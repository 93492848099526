import {Outlet} from "react-router-dom";

function Posts() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Posts;
