import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {Helmet, HelmetProvider} from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>AIとみんなで作る恋愛相談コミュニティ - AI恋愛相談ひろば</title>
        <meta
          name="description"
          content="「AI恋愛相談ひろば」は、AI技術を活用した恋愛相談コミュニティで、恋愛に関する悩みや疑問を解決します。AIの迅速な回答やみんなのコメントをもとに、恋愛に悩んでいる男女をサポートします。"
        />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
