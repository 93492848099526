import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Description from "../component/Description";
import NewQuestion from "../component/NewQuestion";
import Promotion from "../component/Promotion";
import WriteQuestionModal from "../component/WriteQuestionModal";

import {Link} from "react-router-dom";

// const CustomizedTextField1 = styled(TextField)({
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor: "red",
//     // background: "red",
//   },
// });

const CustomButton = styled(Button)({
  backgroundColor: "#EE9898",
  width: "80%",
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
});

function Toppage() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* <h1>ホーム</h1>
      <div>
        新規登録は<Link to={`/qa/10`}>こちら</Link>
      </div> */}
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="md">
          <Stack>
            <Stack alignItems="flex-start" sx={{marginBottom: 2}}>
              <Box sx={{borderBottom: 2}}>
                <Typography component="h1" variant="h3" color="text.primary">
                  AIとみんなで作る恋愛相談コミュニティ
                </Typography>
              </Box>
            </Stack>

            <Description />
            <Box
              component="img"
              sx={{
                maxWidth: "90%",
                height: "auto",
              }}
              alt="toppage-banner"
              src="https://firebasestorage.googleapis.com/v0/b/ai-chiehiroba.appspot.com/o/Image%2Ftoppage-banner.png?alt=media&token=a09303d8-6d07-423c-89d2-4e24d8524f1a"
            />

            <Stack
              sx={{pt: 4}}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <CustomButton variant="contained" onClick={handleOpen}>
                すぐに相談する
              </CustomButton>
            </Stack>

            <NewQuestion page={1} topageFlag={true} />

            <Button size="small" component={Link} to="/questions/">
              もっとみる
            </Button>
          </Stack>

          {/* promotion */}
          <Promotion />

          <WriteQuestionModal open={open} handleClose={handleClose} />
        </Container>
      </Box>
    </>
  );
}

export default Toppage;
