import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

import {getNewQuestions, searchNewQuestions} from "../utils/index";

function NewQuestion(props) {
  const {page, topageFlag} = props;
  //   const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  // const questionList = [
  //   {
  //     questionId: 0,
  //     title: "〇〇で悩んでいます",
  //     message: "悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み悩み",
  //   },
  //   {questionId: 1, title: "title1", message: "body1"},
  //   {questionId: 2, title: "title2", message: "body2"},
  //   {questionId: 3, title: "title3", message: "body3"},
  // ];

  const [questionList, setQuestionList] = useState([]);
  useEffect(() => {
    (async () => {
      let questions;
      if (topageFlag) {
        questions = await getNewQuestions();
      } else {
        questions = await searchNewQuestions(page);
      }
      setQuestionList(questions);
    })();
  }, [page, topageFlag]);

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.secondary"
          paragraph
        >
          新着相談
        </Typography>

        <Grid container spacing={4}>
          {questionList.map((question) => {
            const to = "/qa/q" + question.questionId;
            const createdAt = question.createdAt;
            const date = createdAt?.toDate();
            const date_string = date?.toLocaleString();

            return (
              <Grid item key={question.questionId} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <CardMedia
                  component="img"
                  sx={{
                    // 16:9
                    pt: "56.25%",
                  }}
                  image="https://source.unsplash.com/random"
                  alt="random"
                /> */}
                  <CardContent sx={{flexGrow: 1}}>
                    <Typography
                      component="h3"
                      variant="h5"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}
                      // gutterBottom
                    >
                      {question.title}
                    </Typography>
                    <Typography
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 5,
                      }}
                    >
                      {question.message}
                    </Typography>
                    <Typography>{date_string}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      component={Link}
                      to={to}
                      state={{title: question.title, test: "test"}}
                    >
                      AIの回答をみる
                    </Button>
                    {/* <Button size="small">Edit</Button> */}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export default NewQuestion;
